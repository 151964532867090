var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: { title: "유해위험기계기구 기본정보", collapsed: true },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.editable && _vm.equipInfo.plantName
                  ? _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "사업장",
                            value: _vm.equipInfo.plantName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.machineryClassificationName
                  ? _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "기계분류",
                            value: _vm.equipInfo.machineryClassificationName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.machineryName
                  ? _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "기계명",
                            value: _vm.equipInfo.machineryName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.itemNo
                  ? _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "item No",
                            value: _vm.equipInfo.itemNo,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "유해위험기계기구 검사이력",
                tableId: "hazardEquipmentHis",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.equipInfo.hazardEquipmentHistoryModels,
                usePaging: false,
                filtering: false,
                checkClickFlag: false,
                merge: _vm.grid.merge,
                selection: "multiple",
                rowKey: "inspectionNo",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "click"
                        ? [
                            props.row.editFlag !== "C"
                              ? _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      color: "blue",
                                      clickable: true,
                                      editable: _vm.editable,
                                      "text-color": "white",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.linkClick(
                                          props.row,
                                          col.name
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s("클릭") + " ")]
                                )
                              : _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      color: "red",
                                      "text-color": "white",
                                    },
                                  },
                                  [
                                    _c("q-avatar", {
                                      attrs: {
                                        icon: "info",
                                        color: "red",
                                        "text-color": "white",
                                      },
                                    }),
                                    _vm._v(" " + _vm._s("저장하세요") + " "),
                                  ],
                                  1
                                ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              label: "추가",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addItem },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      _vm.equipInfo.hazardEquipmentHistoryModels.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "삭제",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeItem },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      _vm.equipInfo.hazardEquipmentHistoryModels.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.insertUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.equipInfo.hazardEquipmentHistoryModels,
                              mappingType: "POST",
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveHistory,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "suffixTitle" },
                [
                  _c("font", { attrs: { color: "#C10015" } }, [
                    _vm._v(
                      " ※ 각 행에 해당하는 안전검사결과의 버튼을 '클릭'시 안전검사 실시가 가능합니다. "
                    ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }